/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum ActiveArchivedDeleted {
  ACTIVE = "ACTIVE",
  ARCHIVED = "ARCHIVED",
  DELETED = "DELETED",
}

export enum AllProjectsSortOrder {
  created_at_desc = "created_at_desc",
  name = "name",
}

export enum CompanyBillingStatus {
  ACTIVE = "ACTIVE",
  CANCELLED = "CANCELLED",
  FREE = "FREE",
  INACTIVE = "INACTIVE",
  PENDING = "PENDING",
}

export enum CompanyEquipmentPlanType {
  BASIC = "BASIC",
  BUSINESS = "BUSINESS",
  ENTERPRISE = "ENTERPRISE",
  FREE = "FREE",
  PRO = "PRO",
}

/**
 * All feature toggles require super admin access, unless otherwise stated.
 * 
 * Note to developers:
 * 
 * When removing a feature toggle, make sure to create a migration to remove the feature toggle from the companies table.
 */
export enum CompanyFeatureToggle {
  AUTOMATIC_PLACE_CHANGE = "AUTOMATIC_PLACE_CHANGE",
  DEPARTMENTS = "DEPARTMENTS",
  DEVELOPER_FLEET = "DEVELOPER_FLEET",
  DEVELOPER_PROJECTS = "DEVELOPER_PROJECTS",
  DEVELOPER_RENTAL = "DEVELOPER_RENTAL",
  ENCOURAGE_MAIN_RESPONSIBLE_ACCEPT = "ENCOURAGE_MAIN_RESPONSIBLE_ACCEPT",
  GEOFENCES = "GEOFENCES",
  MACHINES = "MACHINES",
  SCIM = "SCIM",
}

export enum CompanyOnboardingChecklistStepType {
  CREATE_COMPANY = "CREATE_COMPANY",
  CREATE_EQUIPMENT = "CREATE_EQUIPMENT",
  CREATE_PLACE = "CREATE_PLACE",
  CREATE_TASK_GROUP = "CREATE_TASK_GROUP",
  CREATE_TEAM = "CREATE_TEAM",
  DOWNLOAD_MOBILE_APP = "DOWNLOAD_MOBILE_APP",
  INVITE_MEMBERS = "INVITE_MEMBERS",
  OPEN_INTERCOM_HELP_CENTER = "OPEN_INTERCOM_HELP_CENTER",
}

/**
 * ISO 3166 names of countries allowed in IB Equipment
 * https: // en.wikipedia.org/wiki/List_of_ISO_3166_country_codes
 */
export enum Country {
  Norway = "Norway",
  Sweden = "Sweden",
}

export enum Currency {
  SEK = "SEK",
  USD = "USD",
}

export enum DataSourceToCompanyRuleType {
  CUSTOMER_NUMBER = "CUSTOMER_NUMBER",
}

export enum DataSourceType {
  appsys = "appsys",
  appsys2 = "appsys2",
  equipmentloop = "equipmentloop",
  generic = "generic",
  hyrma = "hyrma",
  sheets = "sheets",
  winhyra = "winhyra",
}

export enum EntityType {
  Category = "Category",
  Item = "Item",
  Place = "Place",
  ProjectItem = "ProjectItem",
  Task = "Task",
  TaskGroup = "TaskGroup",
}

export enum FleetDeviceState {
  Active = "Active",
  Delivered = "Delivered",
  InService = "InService",
  InServiceAutoBackToActive = "InServiceAutoBackToActive",
  Inactive = "Inactive",
  Installed = "Installed",
  NotWorking = "NotWorking",
  ReplacePending = "ReplacePending",
  Shelfed = "Shelfed",
  ShelfedAtCustomer = "ShelfedAtCustomer",
  TerminationPending = "TerminationPending",
  Unknown = "Unknown",
}

export enum FleetDeviceStatus {
  DeviceUnplugged = "DeviceUnplugged",
  InService = "InService",
  Inactive = "Inactive",
  LateStart = "LateStart",
  LostCommunication = "LostCommunication",
  MissingData = "MissingData",
  NoPosition = "NoPosition",
  NoTrips = "NoTrips",
  Unknown = "Unknown",
  Working = "Working",
}

export enum Language {
  en = "en",
  no = "no",
  sv = "sv",
}

export enum OwnerType {
  company = "company",
  external = "external",
  project = "project",
}

export enum PlaceType {
  CAR = "CAR",
  CONTAINER = "CONTAINER",
  OTHER = "OTHER",
  PROJECT = "PROJECT",
  STORAGE = "STORAGE",
  UNKNOWN = "UNKNOWN",
}

export enum PricingInterval {
  day = "day",
  month = "month",
  once = "once",
  week = "week",
  work_day = "work_day",
  year = "year",
}

export enum TrackingDeviceType {
  IMEI = "IMEI",
  Queclink_IMEI = "Queclink_IMEI",
  SPARK_IMEI = "SPARK_IMEI",
  TQ377_2404_0389 = "TQ377_2404_0389",
  iBeacon = "iBeacon",
}

export enum UserSortOrder {
  default = "default",
  first_name = "first_name",
  gdpr_level = "gdpr_level",
  last_active = "last_active",
  last_name = "last_name",
  name = "name",
}

export enum autoTag {
  customerId = "customerId",
  projectId = "projectId",
}

export interface DataSourceToCompanyRuleInput {
  payload?: string | null;
  type: DataSourceToCompanyRuleType;
}

export interface RemoveUserFromProjectInput {
  userId: string;
  projectId: string;
}

export interface SearchInput {
  atLeastOneOf?: (string | null)[] | null;
}

export interface UpdateItemSourceInput {
  connectionId: string;
  connectionString?: string | null;
  projectIds?: (string | null)[] | null;
  customerIds?: (string | null)[] | null;
  autoTags?: (autoTag | null)[] | null;
  active?: boolean | null;
  depotId?: string | null;
  projectSearch?: SearchInput | null;
  customerSearch?: SearchInput | null;
  note?: string | null;
  publicNote?: string | null;
  archived?: boolean | null;
  skipItemIfTagContains?: (string | null)[] | null;
}

export interface UpdateProjectItemsInput {
  shouldBeReturned?: boolean | null;
  returnClaimed?: boolean | null;
  returnClaimedAt?: string | null;
  predictedStop?: string | null;
  addTagNames?: string[] | null;
  removeTagNames?: string[] | null;
  assignedUserId?: string | null;
  addActivityIds?: string[] | null;
  removeActivityIds?: string[] | null;
  setActivityIds?: string[] | null;
  mainResponsibleUserId?: string | null;
}

export interface UpdateUserInput {
  advertisingId?: string | null;
  archived?: boolean | null;
  email?: string | null;
  firstName?: string | null;
  id: string;
  intercomId?: string | null;
  isSuperAdmin?: boolean | null;
  lastName?: string | null;
  lastReadProductUpdatesAt?: string | null;
  notificationsMutedEnd?: string | null;
  notificationsMutedStart?: string | null;
  phoneNumbers?: (string | null)[] | null;
  profilePic?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
