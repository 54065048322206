import { gql } from '@apollo/client'

export const ALL_NEWS = gql`
  query AllNews {
    news(seeAll: true) {
      description
      id
      isForAdmins
      isPublished
      languages
      postedAt
      title
    }
  }
`

export const FIELDS_OF_FULL_NEWS_POST = gql`
  fragment FieldsOfFullNewsPost on NewsPost {
    description
    id
    isForAdmins
    isPublished
    languages
    postedAt
    title
    translations {
      description
      id
      language
      newsPostId
      readAt
      title
      url
    }
    url
  }
`

export const CREATE_NEWS_POST = gql`
  mutation CreateNewsPost($url: String!) {
    createNewsPost(url: $url, isPublished: false) {
      success
      newsPost {
        ...FieldsOfFullNewsPost
      }
    }
  }

  ${FIELDS_OF_FULL_NEWS_POST}
`

export const UPDATE_NEWS_POST = gql`
  mutation UpdateNewsPost(
    $id: ID!
    $isForAdmins: Boolean
    $isPublished: Boolean
    $postedAt: String
  ) {
    updateNewsPost(
      id: $id
      isForAdmins: $isForAdmins
      isPublished: $isPublished
      postedAt: $postedAt
    ) {
      success
      newsPost {
        ...FieldsOfFullNewsPost
      }
    }
  }

  ${FIELDS_OF_FULL_NEWS_POST}
`

export const DELETE_NEWS_POST = gql`
  mutation DeleteNewsPost($id: ID!) {
    deleteNewsPost(id: $id) {
      success
    }
  }
`

export const NEWS_POST = gql`
  query NewsPost($id: ID!) {
    newsPost(id: $id) {
      ...FieldsOfFullNewsPost
    }
  }

  ${FIELDS_OF_FULL_NEWS_POST}
`

export const ADD_URL = gql`
  mutation AddNewsPostUrl($newsPostId: ID!, $url: String!) {
    createNewsPostTranslation(newsPostId: $newsPostId, url: $url) {
      success
      newsPost {
        ...FieldsOfFullNewsPost
      }
    }
  }

  ${FIELDS_OF_FULL_NEWS_POST}
`

export const REMOVE_URL = gql`
  mutation RemoveNewsPostUrl($newsPostId: ID!, $url: String!) {
    deleteNewsPostTranslation(newsPostId: $newsPostId, url: $url) {
      success
      newsPost {
        ...FieldsOfFullNewsPost
      }
    }
  }

  ${FIELDS_OF_FULL_NEWS_POST}
`
