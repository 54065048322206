import { useState } from 'react'

import { useMutation } from '@apollo/client'
import { Switch } from '@material-ui/core'

import { UPDATE_NEWS_POST } from './graphql'

export default ({ id, isPublished }) => {
  const [isPublishedState, setIsPublishedState] = useState(isPublished)
  const [updateNewsPost] = useMutation(UPDATE_NEWS_POST)
  const handleChange = (event) => {
    const newPublishedState = event.target.checked
    setIsPublishedState(newPublishedState)
    updateNewsPost({ variables: { id, isPublished: newPublishedState } })
  }
  return <Switch checked={isPublishedState} onChange={handleChange} />
}
